import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Model, StylesManager } from 'survey-core';
import { Survey } from 'survey-react-ui';
import WrapperCard from '../components/UI/WrapperCard';
import useFirestoreSurveys from '../hooks/useFirestore/useFirestoreSurveys.js';
import Spinner from '../components/UI/Spinner';
import 'survey-core/defaultV2.css';
import './SurveyDisplay.css';

StylesManager.applyTheme('defaultV2');

export function SurveyDisplay() {
  const { surveyId, userId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const { getSurveys, saveResponsesToFirestore, getSurveyAvailability, getIsPublicStateForSurvey } =
    useFirestoreSurveys();

  const [json, setJson] = useState(null);
  const [model, setModel] = useState(null);
  const [isSurveyPublic, setIsSurveyPublic] = useState(null);
  const [error, setError] = useState({
    hasError: false,
    errorText: '',
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hasDistributionInterval, setHasDistributionInterval] = useState(null);

  const currentDateMiliseconds = new Date().getTime();

  const fetchSurveyData = useCallback(async () => {
    try {
      await getSurveys(setJson, surveyId, userId);
    } catch (err) {
      console.log('err', err);
    }
  }, []);

  const fetchSurveyAvailability = async () => {
    try {
      setIsLoading(true);
      const data = await getSurveyAvailability(userId, surveyId);
      setStartDate(data.startDate.toDate());
      setEndDate(data.endDate.toDate());
      setHasDistributionInterval(data.hasDistributionInterval);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError({
        hasError: true,
        errorText: 'Intervalul de distribuire nu a fost setat încă pentru acest chestionar.',
      });
    }
  };

  const getIsPublic = async () => {
    try {
      setIsLoading(true);
      setIsSurveyPublic(await getIsPublicStateForSurvey(userId, surveyId));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log('err', err);
      setError({
        hasError: true,
        errorText:
          'Chestionarul nu a putut fi găsit. Acesta fie a fost șters, fie nu mai este activ.',
      });
    }
  };

  useEffect(() => {
    if (surveyId) {
      fetchSurveyData();
      fetchSurveyAvailability();
      getIsPublic();
    }
  }, []);

  console.log('getIsPublicStateForSurvey', isSurveyPublic);

  useEffect(() => {
    if (json) {
      setModel(new Model(json));
    }
  }, [json]);

  const onComplete = async (survey) => {
    await saveResponsesToFirestore(survey.data, surveyId, userId);
  };

  // Return the survey if no distribution interval is setted
  if (!hasDistributionInterval && isSurveyPublic && !isLoading) {
    return <div id="container">{model && <Survey model={model} onComplete={onComplete} />}</div>;
  }

  return (
    <>
      {isLoading && <Spinner generalSpinner={true} />}
      {error.hasError && !isLoading && (
        <WrapperCard noActions={true}>
          <p>{error.errorText}</p>
        </WrapperCard>
      )}
      {!isSurveyPublic && !error.hasError && !isLoading && (
        <WrapperCard noActions={true}>
          <p>Chestionar încă nu este public.</p>
        </WrapperCard>
      )}
      {/* Survey is not ready for completion */}
      {!isLoading &&
        currentDateMiliseconds < startDate.getTime() &&
        hasDistributionInterval &&
        !isLoading && (
          <WrapperCard noActions={true}>
            <>
              <p>Chestionarul încă nu este activ pentru completare.</p>
              <p>Dată start: {new Date(startDate).toLocaleDateString('ro-RO')}</p>
            </>
          </WrapperCard>
        )}
      {/* Survey expired */}
      {!isLoading &&
        hasDistributionInterval &&
        currentDateMiliseconds > endDate.getTime() &&
        !isLoading && (
          <WrapperCard noActions={true}>
            <>
              <p>Chestionarul nu mai este activ.</p>
              <p>Dată expirare: {new Date(endDate).toLocaleDateString('ro-RO')}</p>
            </>
          </WrapperCard>
        )}
      <div id="container">{model && <Survey model={model} onComplete={() => {
        onComplete();
      }} />}</div>;
    </>
  );
}
