import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Firebase configuration variables loaded from environment variables
const clientCredentials = {
  apiKey: 'AIzaSyDQkfJqM8tNufqsK2hljFbohefIUC9IhsU',
  authDomain: 'market-survey-c6124.firebaseapp.com',
  projectId: 'market-survey-c6124',
  storageBucket: 'market-survey-c6124.appspot.com',
  messagingSenderId: '854556119885',
  appId: '1:854556119885:web:73b9bc820112c265c71d04',
};

// If Firebase isn't already initialized, initialize it using the above credentials.
if (!firebase.apps.length) {
  firebase.initializeApp(clientCredentials);
}

export default firebase;
