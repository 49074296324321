import firebase from './firebaseClient';
import getStripe from './initializeStripe';

export async function createCheckoutSession(uid, type) {
  const firestore = firebase.firestore();

  const priceTypes = {
    Nelimitat: 'price_1MuFNvCJtPUFaBVIN875Dj60',
    Premium: 'price_1MuFLUCJtPUFaBVIhwodlrzU',
    Standard: 'price_1MrjtxCJtPUFaBVIKEEceUCk',
  };

  // Create a new checkout session in the subollection inside this users document
  const checkoutSessionRef = await firestore
    .collection('users')
    .doc(uid)
    .collection('checkout_sessions')
    .add({
      // replace the price_XXX value with the correct value from your product in stripe.
      price: priceTypes[type],
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

  // Wait for the CheckoutSession to get attached by the extension
  checkoutSessionRef.onSnapshot(async (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
}
