import React, { useCallback, useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { collection, getFirestore, getDocs, doc } from '@firebase/firestore';

const firestore = getFirestore();

export const SUBSCRIPTION_TYPES = {
  unlimited: 'unlimited',
  premium: 'premium',
  standard: 'standard',
};

export const SUBSCRIPTION_CONFIG = {
  unlimited: {
    price: 500,
    label: 'Nelimitat',
    subscriptionNumber: 1000,
  },
  premium: {
    price: 50,
    label: 'Premium',
    subscriptionNumber: 6,
  },
  standard: {
    price: 10,
    label: 'Standard',
    subscriptionNumber: 3,
  },
};

export const getSubscriptions = async (userId) => {
  const querySnapshot = await getDocs(collection(firestore, `users/${userId}/subscriptions`));
  const subscriptions = querySnapshot.docs.map((doc) => {
    return doc.data();
  });

  return subscriptions;
};

export const SubscriptionContext = React.createContext({
  subscriptions: [],
  setSubscriptions: () => {},
  canCreatSurvey: false,
  setCanCreateSurvey: () => {},
  currentSurveyNumber: 0,
  setCurrentSurveysNumber: () => {},
});

const SubscriptionContextProvider = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [canCreatSurvey, setCanCreateSurvey] = useState(false);
  const [currentSurveyNumber, setCurrentSurveysNumber] = useState(0);

  useEffect(() => {
    let uid = '';
    if (localStorage.getItem('userData')) {
      uid = JSON.parse(localStorage.getItem('userData')).uid;
    }
    fetchSubscriptionsData(uid);
  }, []);

  const fetchSubscriptionsData = useCallback(async (uid) => {
    const subscriptions = await getSubscriptions(uid);
    setSubscriptions(subscriptions);
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptions,
        setSubscriptions,
        canCreatSurvey,
        setCanCreateSurvey,
        currentSurveyNumber,
        setCurrentSurveysNumber,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContextProvider;
