import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise = null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      'pk_live_51MrCZ3CJtPUFaBVIaL6BbRYZmhxBqUQNGlEf4y5DBhPNj6J8TJLYcXSAAUdCWGg58hbOCtwXJk4qUe3AHzRRXnX700uZugpP8c'
    );
  }
  return stripePromise;
};
export default initializeStripe;
