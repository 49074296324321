import { createCheckoutSession } from '../../stripe/createCheckoutSession';
import classes from './PaymentItem.module.css';
import axios from 'axios';

const PaymentItem = ({
  uid,
  label,
  price,
  surveysAllowed,
  recommended,
  setIsLoading,
  activeSubscription,
  isUnlimited,
}) => {
  const styles = `${classes['payment-card']} ${recommended ? classes.recommended : ''}`;

  return (
    <div className={styles}>
      <div className={classes['payment-card__description']}>
        <p>
          {label} {recommended && '(Recomandat)'}
        </p>
        <span style={{ color: activeSubscription && '#7b7d8a' }}>{price} lei / lună</span>
      </div>
      <div
        className={classes['payment-card__surveys-available']}
        style={{ marginLeft: recommended && '-5rem' }}
      >
        {!isUnlimited && (
          <>
            {surveysAllowed} {typeof surveysAllowed !== 'string' && 'sondaje pe lună'}
          </>
        )}
      </div>
      <div
        className={classes.select}
        onClick={() => {
          if (activeSubscription) {
            const url =
              'https://us-central1-market-survey-c6124.cloudfunctions.net/deleteStripeSubscriptionRequestNew';
            var subscriptionId = localStorage.getItem('subscription');
            axios.post(url, { subscriptionId }).then((res) => {
              console.log(res);
              console.log(res.data);
              window.location.reload(true);
            });
          } else {
            setIsLoading(true);
            createCheckoutSession(uid, label);
          }
        }}
        style={{ backgroundColor: activeSubscription && '#FF5D5D' }}
      >
        {activeSubscription ? 'Renunță' : 'Selectează'}
      </div>
    </div>
  );
};

export default PaymentItem;
