import { useState, useEffect } from 'react';
import {
  SUBSCRIPTION_CONFIG,
  SUBSCRIPTION_TYPES,
} from '../../context/payment-context/payment-context';
import classes from './Payment.module.css';
import PaymentItem from './PaymentItem';
import Spinner from './Spinner';

const Payment = ({ uid, subscriptions, currentSurveyNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const activeSubscription =
    subscriptions.length > 0 ? subscriptions.find((item) => item.status === 'active') : null;

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  const getCurrentSubscriptionModalContent = () => {
    const subscriptionType = activeSubscription.items[0].price.product.metadata['firebaseRole'];
    const maxSubscriptions = SUBSCRIPTION_CONFIG[subscriptionType].subscriptionNumber;

    return (
      <>
        {subscriptionType !== SUBSCRIPTION_TYPES.unlimited && (
          <p className={classes['title__survey--used']}>
            {`Ați folosit ${currentSurveyNumber} din ${maxSubscriptions} formulare în perioada curentă`}
          </p>
        )}

        <PaymentItem
          uid={uid}
          label={SUBSCRIPTION_CONFIG[subscriptionType].label}
          price={SUBSCRIPTION_CONFIG[subscriptionType].price}
          surveysAllowed={maxSubscriptions}
          setIsLoading={setIsLoading}
          activeSubscription={true}
          isUnlimited={subscriptionType === SUBSCRIPTION_TYPES.unlimited}
        />
      </>
    );
  };

  return (
    <>
      {isLoading && <Spinner generalSpinner={true} />}
      {activeSubscription ? (
        <>
          <h4 className={classes.title} style={{ marginBottom: '.50rem' }}>
            Abonamentul curent
          </h4>
          {getCurrentSubscriptionModalContent()}
        </>
      ) : (
        <>
          <h4 className={classes.title}>Alege tipul abonamentului</h4>
          <>
            <PaymentItem
              uid={uid}
              label="Standard"
              price={10}
              surveysAllowed={3}
              setIsLoading={setIsLoading}
            />
            <PaymentItem
              uid={uid}
              label="Premium"
              price={50}
              surveysAllowed={6}
              recommended={true}
              setIsLoading={setIsLoading}
            />
            <PaymentItem
              uid={uid}
              label="Nelimitat"
              price={500}
              surveysAllowed="Sondaje nelimitate (anual)"
              setIsLoading={setIsLoading}
            />
          </>
        </>
      )}
    </>
  );
};

export default Payment;
