import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  FacebookAuthProvider,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDQkfJqM8tNufqsK2hljFbohefIUC9IhsU',
  authDomain: 'market-survey-c6124.firebaseapp.com',
  projectId: 'market-survey-c6124',
  storageBucket: 'market-survey-c6124.appspot.com',
  messagingSenderId: '854556119885',
  appId: '1:854556119885:web:73b9bc820112c265c71d04',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const useFirebaseAuthentication = () => {
  // GOOGLE
  const signInWithFirebase = async () => {
    try {
      const user = await signInWithPopup(auth, provider);
      return user.user;
    } catch (err) {
      console.log('firebase fetching error', err);
    }
  };

  const signOutFromFirebase = async () => {
    await signOut();
  };

  // FORGOT PASSWORD
  const forgotPasswordHandler = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      throw new Error(err.message || err);
    }
  };

  // FACEBOOK
  const signInWithFacebook = async () => {
    try {
      const user = await signInWithPopup(auth, facebookProvider);
      return user.user;
    } catch (err) {
      throw new Error(err.message || err);
    }
  }; 

  return {
    signInWithFirebase,
    signOutFromFirebase,
    forgotPasswordHandler,
    signInWithFacebook,
  };
};
export default useFirebaseAuthentication;
